.tabs-container {
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.tabs {
    display: flex;
    border-bottom: 2px solid #ddd;
}

.tab-btn {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: 2px solid transparent;
}

.tab-btn.active {
    border-bottom: 2px solid #007bff;
    font-weight: bold;
    color: #007bff;
}

.tab-btn:hover {
    color: #007bff;
}

.tab-content {
    padding: 15px;
    border: 1px solid #ddd;
    margin-top: -1px;
}

.tab-panel {
    display: block;
}