.main-ont-heading {
  opacity: 1 !important;
  font-weight: bolder !important;
  font-size: 25px !important;
}
.ont-status-counter-container {
  float: right !important;
  font-weight: bold !important;
}
.mr-20p {
  margin-right: 20px !important;
}

.color-online {
  color: #4cc467 !important;
}
.color-offline {
  color: #ff4646 !important;
}

.color-upset {
  color: #ea613e !important;
}

.color-inactive {
  color: #99b4ac !important;
}

.color-invisible {
  color: #4682b4 !important;
}
.status-counter-wrapper {
  display: inline-block;
  width: 120px;
  height: 30px;
  border: 1px solid gray !important;
  text-align: center;
  line-height: 28px;
  border-radius: 20px;
}
.color-too-high {
  color: blue !important;
}
.status-counter-signal {
  display: inline-block;
  width: 160px;
  height: 30px;
  border: 1px solid gray !important;
  text-align: center;
  line-height: 28px;
  border-radius: 20px;
}
.single-ont-container {
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  border-radius: 40px;

  padding: 20px;
}
.image-float-right {
  float: right !important;
}
.image-float-left {
  float: left !important;
}
.mx-h-100 {
  max-height: 100px !important;
}
.end-user-name-heading {
  color: #ff4646 !important;
  /* font-weight: bold; */
}
.single-ont-container-extended {
  width: 100%;
  /* height: 900px; */
  /* border: 1px solid black; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  border-radius: 40px;

  padding: 20px;
}
.active-tab-button-ont-list {
  display: inline-block;
  width: 120px;
  height: 35px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #ffffff !important;
  background: #00acee;
  border: #00acee;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
}

.inactive-tab-button-ont-list {
  display: inline-block;
  width: 120px;
  height: 35px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000 !important;
  background: #ffffff;
  border: 0.5px solid #c8c8c8;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
}
.single-td-blocked-list {
  background: linear-gradient(
    0deg,
    rgba(0, 172, 238, 0.15),
    rgba(0, 172, 238, 0.15)
  );

  padding: 10px;
  border-bottom: 3px solid white;
}
.single-item-blocked-list {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.blocked-onts-list-image-icon {
  max-height: 20px;
  max-width: 20px;
  margin-right: 10px;
}
.inactive-tab-button-ont-list-disabled {
  display: inline-block;
  width: 120px;
  height: 35px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000 !important;
  background: #c8c8c8;
  border: 0.5px solid #c8c8c8;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
}
