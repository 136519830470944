.olt-heading {
  font-weight: bold;
  opacity: 0.7;
}
.olt-heading-small {
  font-weight: bold;
  opacity: 0.7;
  margin-top: 20px !important;
  font-size: 18px !important;
}
.olt-counter {
  float: right !important;
  background: var(--primary) !important;
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  border-radius: 50% !important;
  color: white !important;
}
.reboot-olt-button {
  display: inline-block;
  width: 170px;
  height: 45px;
  border-radius: 20px;
  border: 0.30000001192092896px;
  background: linear-gradient(0deg, #48a3ff, #48a3ff),
    linear-gradient(0deg, #ffffff, #ffffff);
  color: #ffffff;
  line-height: 40px;
  cursor: pointer;
}

.rebooting-olt-button {
  display: inline-block;
  width: 170px;
  height: 45px;
  border-radius: 20px;
  border: 1px solid #48a3ff;
  color: #48a3ff;
  line-height: 40px;
}
.create-vlan-button {
  width: 140px;
  height: 40px;
  border-radius: 30px;
  background: #ff4646;
  border: #ff4646;
  float: right;
  margin-top: 5px;
  color: white;
}
.pon-port-action-item-disabled {
  background: #e7e7e7 !important;
}
