.routerContainer {
  background: linear-gradient(180deg, #0db2f1 0%, #44cbff 100%);
  height: 100vh;
  color: white;
}
.mt-10-custom {
  margin-top: 100px !important;
  opacity: 0.9;
}
.h-450 {
  max-height: 450px !important;
  margin-top: 80px !important;
}

.mt-15-custom {
  margin-top: 120px !important;
  text-align: center;
}
.mt-6-custom {
  margin-top: 55px !important;
  text-align: center;
}
.mt-1-custom {
  margin-top: 10px !important;
  text-align: center;
}
.login-form-main-container {
  min-width: 321px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.min-w-100 {
  min-width: 400px !important;
  width: 400px !important;
}
.custom-error-message {
  text-align: left !important;
  color: rgb(240, 104, 104); /* You can customize the color */
  margin-top: 0.25rem; /* Adjust the margin as needed */
}
