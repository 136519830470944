.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #929292;
  height: 4rem;
}
.steps > .step {
  position: relative;
  display: table-cell;
  text-align: center;
  font-size: 0.875rem;
  color: #6d6875;
}
.steps > .step:before {
  content: attr(data-step);
  display: block;
  margin: 0 auto;
  background: #fff;
  border: 2px solid #e6e6e6;
  color: #e6e6e6;
  width: 2rem;
  height: 2rem;
  text-align: center;
  margin-bottom: -4.2rem;
  line-height: 1.9rem;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 1rem;
}
.steps > .step:after {
  content: "";
  position: absolute;
  display: block;
  background: #e6e6e6;
  width: 100%;
  height: 0.125rem;
  top: 1rem;
  left: 50%;
}
.steps > .step:last-child:after {
  display: none;
}
.steps > .step.is-complete {
  color: #6d6875;
}
.steps > .step.is-complete:before {
  content: "\2713";
  color: #f68e20;
  background: #fef0e2;
  border: 2px solid #f68e20;
}
.steps > .step.is-complete:after {
  background: #f68e20;
}
.steps > .step.is-active {
  font-size: 1.5rem;
}
.steps > .step.is-active:before {
  color: #fff;
  border: 2px solid #f68e20;
  background: #f68e20;
  margin-bottom: -4.9rem;
}

.steps {
  margin-bottom: 3em;
}

.steps > .step.is-failed:before {
  content: "\274C";
  color: #f68e20;
  background: #fef0e2;
  border: 2px solid #f68e20;
}
.steps > .step.is-failed:after {
  background: #f68e20;
}
.steps > .step.is-all-complete {
  color: #11dc00;
}
.steps > .step.is-all-complete {
  color: #11dc00;
}
.steps > .step.is-all-complete:before {
  content: "\2713";
  color: #11dc00;
  background: #fef0e2;
  border: 2px solid #11dc00;
}
.steps > .step.is-all-complete:after {
  background: #11dc00;
}
