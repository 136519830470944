:root {
  --primary: #00acee;
  --dark: #061b28;
  --gray: #756388;
  --light: #ffffff;
  --upStatus: #11dc00;
  --downStatus: #ff262b;
  --upsetStatus: #ffd120;
  --inactiveStatus: #b7b7b7;
  --invisibleStatus: #fa474b;
  --inProcessStatus: #1e5cff;
  --healthySignals: #00cc60;
  --upsetSignals: #fe7f00;
  --badSignals: #ff4e12;
  --tooHighSignals: #1e5cff;
  --totalEndUsers: #6768ab;
  --relatedEndUsers: #ff7062;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-secondary {
  min-width: 392px;
  min-height: 45px;
  background: #4cc467;
  color: white;
  font-weight: bold;
  border-color: #4cc467;
  border: 1px solid transparent;
  border-radius: 20px;
}
.button-blue {
  min-width: 392px;
  min-height: 45px;
  background: var(--primary);
  color: white;
  font-weight: bold;
  border-color: var(--primary);
  border: 1px solid transparent;
  border-radius: 20px;
}
.btn-success {
  min-width: 90px;
  min-height: 30px;
  background: #4cc467;
  color: white;
  font-weight: bold;
  border-color: #4cc467;
  border: 1px solid transparent;
  border-radius: 20px;
}
.button-primary {
  min-width: 392px;
  min-height: 45px;
  background: #ff724e;
  color: white;
  font-weight: bold;
  border-color: #ff724e;
  border: 1px solid transparent;
  border-radius: 20px;
}
.button-primary:hover {
  color: white;
  background: #4cc467;
}
.button-primary:focus {
  outline: none;
  box-shadow: 0 0 0 5px gray;
}
.button-blue:hover {
  color: white;
  background: #4cc467;
}

.olt-image-primary {
  max-height: 400px;
}
.online-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #4cc467;
  border-radius: 50%;
  vertical-align: middle;
}

.offline-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #ff4646;
  border-radius: 50%;
  vertical-align: middle;
}

.upset-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #ea613e;
  border-radius: 50%;
  vertical-align: middle;
}
.inactive-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #99b4ac;
  border-radius: 50%;
  vertical-align: middle;
}
.invisible-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #4682b4;
  border-radius: 50%;
  vertical-align: middle;
}

.stats-counter {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
}

.button-primary-search {
  background: #ff4646;
  color: white;
  font-weight: bold;
  border-color: #ff4646;
  background: #ff4646;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid transparent;
  height: 50px;
  width: 60px;
}
.button-primary-search:hover {
  color: white;
  background: #4cc467;
}
.button-primary-search:focus {
  outline: none;
  box-shadow: 0 0 0 5px gray;
}
.data-r-label {
  color: var(--primary);
  font-size: 15px;
  font-weight: 600;
}
.data-r-label-black {
  color: #696969;
  font-size: 20px;
  font-weight: 600;
}

.data-r-field {
  color: #696969;
  font-size: 15px;
  font-weight: 400;
}
.data-r-field-red {
  color: #ff4646;
  font-size: 20px;
  font-weight: 400;
}

.data-r-label-green {
  color: #4cc467;
  font-size: 20px;
  font-weight: 600;
}

.text-blue-heading {
  color: var(--primary) !important;
  font-weight: bolder;
}
.fw-700 {
  font-weight: 700 !important;
}
.min-height-50p {
  min-height: 50px !important;
}
.input-shade-gray {
  background: linear-gradient(0deg, #f4f4f4, #f4f4f4) !important;
  border: 1px solid #c8c8c8 !important;
}
.bd-35p {
  border-radius: 35px !important;
}
.table-header-standard {
  color: #4cc467 !important;
  min-height: 50px;
  line-height: 50px;
  height: 50px;
}
.overlay-custom-class-name-for-alert {
  z-index: 9999 !important;
}
.cursor-pointer-class {
  cursor: pointer;
}
.show-caret-on-hover:hover .show-caret-down:after {
  content: "▼";
  color: #4cc467;
}
.link-class {
  text-decoration: none !important;
  max-width: 100px !important;
  min-width: 152px !important;
  display: inline-block !important;
  height: 50px !important;
  line-height: 50px !important;
  border-radius: 10px !important;
  text-align: center !important;
}
.edit-user-items-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.edit-user-item {
  width: 420px !important;
  height: 50px !important;
  border-radius: 30px !important;
  background-color: #f4f4f4 !important;
  border: 1px solid #e7e7e7 !important;
  margin-top: 10px;
}
.end-user-item-name {
  display: inline-block;
  height: 50px;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 50px;
  margin-left: 20px;
}
.end-user-item-action {
  margin-right: 20px;
  height: 50px;
  line-height: 45px;
  float: right;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.button-plus-icon {
  min-width: 392px;
  min-height: 45px;
  background: white;
  color: white;
  font-weight: bold;
  border-color: #ff724e;
  border: 1px solid #ff724e;
  border-radius: 20px;
}
.single-stats-item-container {
  box-shadow: 0px 0px 4px 0px #00000026;
  height: 150px;
  position: relative !important;
  padding: 15px;
}
.single-stats-item-container-status-text {
  font-family: "General Sans" !important;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  font-size: 20px;
}
.stats-image-style {
  width: 30px !important;
  height: 30px !important;
  margin-top: 2px;
}
.single-stats-item-container-bottom-counter {
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.single-stats-item-bottom-counter-text {
  /* font-family: "General Sans"; */
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  color: white;
}
.bg-up {
  background: var(--upStatus);
}
.color-up {
  color: var(--upStatus);
}
.bg-down {
  background: var(--downStatus);
}

.color-down {
  color: var(--downStatus);
}

.color-upset-dashboard {
  color: var(--upsetStatus);
}
.bg-upset-dashboard {
  background: var(--upsetStatus);
}

.color-inactive-dashboard {
  color: var(--inactiveStatus);
}
.bg-inactive-dashboard {
  background: var(--inactiveStatus);
}

.color-invisible-dashboard {
  color: var(--invisibleStatus);
}
.bg-invisible-dashboard {
  background: var(--invisibleStatus);
}

.color-inprocess-dashboard {
  color: var(--inProcessStatus);
}
.bg-inprocess-dashboard {
  background: var(--inProcessStatus);
}

.color-healthy-signals-dashboard {
  color: var(--healthySignals);
}
.bg-healthy-signals-dashboard {
  background: var(--healthySignals);
}

.color-upset-signals-dashboard {
  color: var(--upsetSignals);
}
.bg-upset-signals-dashboard {
  background: var(--upsetSignals);
}

.color-bad-signals-dashboard {
  color: var(--badSignals);
}
.bg-bad-signals-dashboard {
  background: var(--badSignals);
}

.color-tooHigh-signals-dashboard {
  color: var(--tooHighSignals);
}
.bg-tooHigh-signals-dashboard {
  background: var(--tooHighSignals);
}
.color-totalEndUsers-dashboard {
  color: var(--totalEndUsers);
}
.bg-totalEndUsers-dashboard {
  background: var(--totalEndUsers);
}

.color-relatedEndUsers-dashboard {
  color: var(--relatedEndUsers);
}
.bg-relatedEndUsers-dashboard {
  background: var(--relatedEndUsers);
}

.pl-15 {
  padding-left: 15px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.pie-chart-parent-container {
  box-shadow: 0px 0px 4px 0px #00000026;
  padding: 40px;
}

/* Dashboard Circles */

.online-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--upStatus);
  border-radius: 50%;
  vertical-align: middle;
}

.offline-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--downStatus);
  border-radius: 50%;
  vertical-align: middle;
}

.upset-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--upsetStatus);
  border-radius: 50%;
  vertical-align: middle;
}

.inactive-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--inactiveStatus);
  border-radius: 50%;
  vertical-align: middle;
}

.invisible-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--invisibleStatus);
  border-radius: 50%;
  vertical-align: middle;
}

.inprocess-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--inProcessStatus);
  border-radius: 50%;
  vertical-align: middle;
}

.too-high-signal-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--tooHighSignals);
  border-radius: 50%;
  vertical-align: middle;
}

.healthy-signal-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--healthySignals);
  border-radius: 50%;
  vertical-align: middle;
}

.upset-signal-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--upsetSignals);
  border-radius: 50%;
  vertical-align: middle;
}

.bad-signal-dashboard-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--badSignals);
  border-radius: 50%;
  vertical-align: middle;
}
.standard-btn-coloured {
  color: #00acec !important;
  padding: 10px 20px 10px 20px;
}

.standard-btn-coloured:hover {
  color: #ffffff !important;
  background-color: #009fdb;
}
.custom-select-for-pagination {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  width: 200px;
  outline: none;
  cursor: pointer;
}
.nav-bar-custom-style {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.bullet-point-for-olt-view {
  display: inline-block;
  height: 40px;
  border-radius: 8px;
  border: 0.5px solid #b7b7b7;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}
.olt-card-data-labels {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.cold-temprature {
  color: #43cbff !important;
}

.normal-temprature {
  color: #41a148 !important;
}

.upset-temprature {
  color: #f5b41a !important;
}
.too-high-temprature {
  color: #e94c3d !important;
}
.too-high-blinking-dot {
  float: left !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e94c3d;
  animation: blink 1s infinite;
}

.upset-blinking-dot {
  float: left !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f5b41a;
  animation: blink 1s infinite;
}
@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.push-vlan-to-ont-modal-heading {
  width: 100%;
  height: 40px;
  background: var(--primary);
  line-height: 40px;
  text-align: center;
  color: white;
  border-radius: 20px;
}
.push-vlan-modal-form-row {
  border: 1px solid #c8c8c8;
  height: 80px;
  box-shadow: 0px 0px 4px 0px #c8c8c8;
  border-radius: 10px;
  /* line-height: 80px; */
  display: flex;
  align-items: center;
}
.push-vlan-modal-form-row-all-port-label {
  width: 100%;
  height: 40px;
  background: #aae7ff;
  line-height: 40px;
  /* text-align: center; */
  border-radius: 20px;
}
.push-vlan-modal-form-row-single-port-label {
  width: 100%;
  height: 40px;
  background: #dff6ff;
  line-height: 40px;
  /* text-align: center; */
  border-radius: 20px;
}
.blinking-image {
  animation: blink 1s infinite;
}

@keyframes blinkAnimation {
  0%,
  100% {
    visibility: hidden;
  }
  50% {
    visibility: visible;
  }
}

.signal-label {
  color: #a020f0 !important;
  font-weight: 500;
}
.signal-value-label {
  color: #4cc467 !important;
  font-weight: 500;
}
.failed-process {
  background-color: red !important;
}

.failed-process:hover {
  background-color: red;
}

.installing-process {
  background-color: var(--primary) !important;
}

.installing-process:hover {
  background-color: var(--primary) !important;
}
.signup-link-class {
  color: #00acee !important;
  text-decoration: none !important;
}
.otp-input-single-container {
  min-width: 40px !important;
  min-height: 45px !important;
  background: #f4f4f4 !important;
  border: 1px solid #f4f4f4 !important;
}
.stats-counter-label {
  font-size: 12px;
  font-weight: 400;
}
