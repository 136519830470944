.policy-page {
    font-family: Arial, sans-serif;
    color: black;
    background-color: white;
    padding: 20px;
}

.privacy-policy-main-heading h1 {
    margin-bottom: 20px;
}

.policy-section {
    margin-bottom: 40px;
}

.policy-section h2 {
    color: black;
    /* A darker shade of purple */
    border-bottom: 2px solid black;
    /* A darker shade of purple */
    padding-bottom: 5px;
}

.privacy-policy-footer {
    margin-top: 40px;
    text-align: right;
    font-size: 12px;
}